<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <Toolbar class="p-mb-4">
        <template #left>
          <h5 class="p-m-0">
            {{ $t("customer.list.table_title") }}
          </h5>
        </template>

        <template #right>
          <Button
            label=""
            icon="pi pi-refresh"
            class="p-button-secondary p-mr-2 p-mb*2"
            data-testid="table-refresh"
            @click="onRefreshTable"
          />
        </template>
      </Toolbar>
      <DataTable
        :value="customers"
        :paginator="true"
        :resizable-columns="true"
        column-resize-mode="expand"
        :rows="10"
        :rows-per-page-options="[5, 10, 25]"
      >
        <Column
          field="company"
          :header="translation.company"
          :sortable="true"
        />
        <Column field="contact" :header="translation.contact">
          <template #body="slotProps">
            <span class="p-column-title">{{ translation.contact }}</span>
            {{ slotProps.data.contact.fullName() }}
          </template>
        </Column>
        <Column :header="translation.email" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.contact.email }}
          </template>
        </Column>
        <Column
          field="clients"
          :header="translation.clients"
          :sortable="true"
        />
        <Column field="time" :header="translation.time" :sortable="true">
          <template #body="slotProps">
            <span>{{ slotProps.data.wage.stringyTime() }}</span>
          </template>
        </Column>
        <Column field="time" :header="translation.storage" :sortable="true">
          <template #body="slotProps">
            <span>{{ slotProps.data.wage.stringyStorage() }}</span>
          </template>
        </Column>
        <Column style="width: 5rem; text-align: right">
          <template #body="slotProps">
            <Button
              icon="pi pi-info"
              class="p-button-rounded p-button-info p-mr-2"
              :data-testid="slotProps.data.id"
              @click="onInformation(slotProps.data)"
            />
          </template>
        </Column>
        <template #empty>{{ $t("customer.list.table_empty") }} </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Toolbar from "primevue/toolbar";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { FETCHING_CUSTOMERS } from "../store/modules/customer/customer.actions.type";
import { GET_CUSTOMERS } from "../store/modules/customer/customer.getters.type";

export default {
  name: "TableCustomers",
  components: {
    Button,
    Column,
    DataTable,
    Toolbar,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const customers = computed(() => store.getters[GET_CUSTOMERS]);
    const translation = {
      company: t("customer.list.table_column_company"),
      contact: t("customer.list.table_column_contact"),
      email: t("customer.list.table_column_email"),
      clients: t("customer.list.table_column_clients"),
      time: t("customer.list.table_column_time"),
      storage: t("customer.list.table_column_storage"),
    };

    async function onRefreshTable() {
      await store.dispatch(FETCHING_CUSTOMERS);
    }

    function onInformation(data) {
      router.push({ name: "accounting", params: { customerId: data.id } });
    }

    return {
      customers,
      onRefreshTable,
      onInformation,
      translation,
    };
  },
};
</script>
