<template>
  <section>
    <table-customers />
  </section>
</template>
<script>
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";

import TableCustomers from "../../components/TableCustomers";
import { FETCHING_CUSTOMERS } from "../../store/modules/customer/customer.actions.type";
import { GET_CUSTOMERS } from "../../store/modules/customer/customer.getters.type";

export default {
  name: "CustomerList",
  components: { TableCustomers },
  setup() {
    const store = useStore();

    onMounted(async () => {
      if (store.getters[GET_CUSTOMERS].length === 0) {
        await store.dispatch(FETCHING_CUSTOMERS);
      }
    });
  },
};
</script>
